import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => {
          return import("@/views/Dashboard.vue");
        },
      },
      {
        path: "/kitap",
        name: "kitap",
        component: () => import("@/views/kitap/Kitap.vue"),
      },
      {
        path: "/user",
        name: "user",
        component: () => import("@/views/User.vue"),
      },
      {
        path: "/kitap/icerik",
        name: "icerik",
        component: () => import("@/views/kitap/Icerik.vue"),
      },
      {
        path: "/ansiklopedi",
        name: "ansiklopedi",
        component: () => import("@/views/ansiklopedi/Ansiklopedi.vue"),
      },
      {
        path: "/Biyografi",
        name: "Biyografi",
        component: () => import("@/views/biyografi/Biyografi.vue"),
      },
      {
        path: "/iletisimbilgileri",
        name: "iletisim-bilgileri",
        component: () => import("@/views/iletisim/IletisimBilgileri.vue"),
      },
      {
        path: "/iletisimform",
        name: "iletisim-form",
        component: () => import("@/views/iletisim/IletisimForm.vue"),
      },
      {
        path: "/dosya",
        name: "Dosya",
        component: () => import("@/views/dosya/Dosya.vue"),
      },

      {
        path: "/iceriktipi",
        name: "IcerikTipi",
        component: () => import("@/views/icerik-tipi/IcerikTipi.vue"),
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// router.beforeEach(() => {
//   // reset config to initial state
//   store.commit(Mutations.RESET_LAYOUT_CONFIG);
//
//   store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });
//
//   // Scroll page to top on every route change
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
// });

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
export default router;
